/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import {
  GET_PROFILES,
  GET_PAGES,
  GET_PROFILES_BY_PAGE,
  GET_GROUPS,
  GET_GROUPS_BY_PARENTS,
  GET_MASTER_PAGES,
  GET_USER_MENU,
  GET_DOCUMET_TYPES,
  GET_BLOOD_TYPE,
  GET_DISCOUNTS_TYPES,
  GET_CITIES,
  GET_DEPARTMENTS,
  GET_COUNTRY,
  GET_CONTACTS,
  GET_PAYMENT_FORMS,
  GET_CURRENCIES,
  GET_REGIME_TYPE,
  GET_PERSON_TYPE,
  GET_ORIENTATION_REPORT,
  GET_VALUES,
  GET_CONFIG_CALENDAR,
  GET_PATIENTS,
  GET_PATIENT_LARAVEL,
  GET_SOCIAL_NETWORKS,
  GET_MARITAL_STATUS,
  GET_ETHNIC_GROUPS,
  GET_SEXUAL_ORIENTATION,
  GET_GENDER,
  GET_BANK,
  GET_FINDDOCTOR,
  GET_PLAN,
  GET_RANGO,
  GET_AFILIACION,
  GET_ESCOLARIDAD,
  GET_OCUPACION,
  GET_ORIGENCONTACTO,
  GET_PARENTESCO,
  GET_FUNCIONALIDAD,
  GET_FUNCIONALIDAD_X_USUARIO,
  GET_UNIDADES_MEDIDA,
  GET_REPORTE_1,
  SIGNALRCON,
  SIGNALRPROXY,
  SET_LAST_ACTION,
  CREATE_BODY_SYSTEM,
  DELETE_BODY_SYSTEM,
  GET_BODY_SYSTEMS,
  CREATE_VARIABLE_UNITSXEXAMS,
  DELETE_VARIABLE_UNITSXEXAMS,
  GET_VARIABLE_UNITSXEXAMS,
  CREATE_TYPES_DATAXEXAMS,
  DELETE_TYPES_DATAXEXAMS,
  GET_TYPES_DATAXEXAMS,
  CREATE_LISTS_SEARCHXEXAMS,
  DELETE_LISTS_SEARCHXEXAMS,
  GET_LISTS_SEARCHXEXAMS,
  CREATE_SYMPTOM,
  DELETE_SYMPTOM,
  GET_SYMPTOMS,
  CREATE_VALUESXSEARCHLIST,
  DELETE_VALUESXSEARCHLIST,
  GET_VALUESXSEARCHLIST,
  SET_EXECUTING_REQUEST_GENERIC,
  CREATE_CUPSTECNICA,
  DELETE_CUPSTECNICA,
  GET_CUPSTECNICA,
  GET_GROUP_CUPS,
  CREATE_DIAGNOSTICOS_CIE,
  DELETE_DIAGNOSTICOS_CIE,
  GET_DIAGNOSTICOS_CIE,
  CREATE_ANTECEDENT_GROUP,
  DELETE_ANTECEDENT_GROUP,
  GET_ANTECEDENT_GROUP,
  CREATE_BACKGROUND,
  DELETE_BACKGROUND,
  GET_BACKGROUND,
  CREATE_PHYSICAL_REGION,
  DELETE_PHYSICAL_REGION,
  GET_PHYSICAL_REGION,
  CREATE_ANTHROPOMETRICA_MEASURES,
  DELETE_ANTHROPOMETRICA_MEASURES,
  GET_ANTHROPOMETRICA_MEASURES,
  GET_BLOOD_TYPES,
  GET_ETHNICAL_GROUPS,
  GET_AGREEMENTS,
  GET_HEALTH_ENTITIES,
} from "./types";

// Utils
import { api as urlApi, urlApiMessagebird } from "../../enviroment";
import axios from "../../Utils/Http";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const urlApiLaravel = require("../../enviroment").apiLaravel;
/**
 * @author Daniel Felipe Lucumi Marin
 * @file GenericAction.js
 * @description Generic actions
 */

export function getProfiles(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PROFILES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getDiscountTypes(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_DISCOUNTS_TYPES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function DeleteProfile(resource, idProfile) {
  return (dispatch) => {
    return axios.delete(`${urlApi}${resource}/${idProfile}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PROFILES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function DeleteGroup(resource, idGroup) {
  return (dispatch) => {
    return axios.delete(`${urlApi}${resource}/${idGroup}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_GROUPS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function DeleteGroupByPage(resource, idGroup) {
  return (dispatch) => {
    return axios.delete(`${urlApi}${resource}/${idGroup}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_GROUPS_BY_PARENTS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getPages(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PAGES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getMasterPages(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_MASTER_PAGES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getGroups(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_GROUPS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getGroupsByParents(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_GROUPS_BY_PARENTS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getProfileByPages(resource, idProfile) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}/${idProfile}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PROFILES_BY_PAGE,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function CreateNewProfile(resource, profile) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, profile).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PROFILES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function CreateNewGroupByParent(resource, groupByParent) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, groupByParent).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_GROUPS_BY_PARENTS,
          payload: res.data,
        });
      }
      return res.data.message;
    });
  };
}

export function CreateNewGroup(resource, group) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, group).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_GROUPS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export async function getConsultingTypes() {
  const response = await axios
    .get(`${urlApiLaravel}api/v1/care-types`)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch((error) => {
      console.log("No se encontraron tipos de consulta", error);
    });

  return response;
}
export async function getCountriesSelect() {
  const response = await axios
    .get(`${urlApiLaravel}api/v1/countries/doctor-search-engine`)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch((error) => {
      console.log("No se encontraron paises", error);
    });

  return response;
}

export async function getCountriesRegister() {
  const response = await axios
    .get(`${urlApiLaravel}api/v1/countries`)
    .then((res) => {
      console.log(res);
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      console.log("No se encontraron paises");
    });

  return response;
}

export function getDepartments(resource, department) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, department).then((res) => {
      if (res.data.code === 0) {
        //
      }
      return res.data;
    });
  };
}

export async function getStates(countryId) {
  const response = await axios
    .get(`${urlApiLaravel}api/v1/states/${countryId}/country`)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      console.log("No se encontraron estados de este pais");
    });

  return response;
}

export function getStatesReducer(countryId) {
  return async (dispatch) => {
    return await axios
      .get(`${urlApiLaravel}api/v1/states/${countryId}/country`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_DEPARTMENTS,
            payload: res.data,
          });
        }
        return res.data.data;
      })
      .catch(() => {
        console.log("No se encontraron estados de este pais");
      });
  };
}

export async function getCitiesSelect(stateId) {
  const response = await axios
    .get(`${urlApiLaravel}api/v1/cities/${stateId}/state`)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      console.log("No se encontraron ciudades");
    });

  return response;
}
export async function getCitiesNew() {
  const response = await axios
    .get(`${urlApiLaravel}api/v1/cities/doctor-search-engine`)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      console.log("No se encontraron ciudades");
    });

  return response;
}

export async function getCitiesWithCups() {
  const response = await axios
    .get(`${urlApiLaravel}api/v2/doctor-search-engine/cups/cities`)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      console.log("No se encontraron ciudades");
    });

  return response;
}


export async function getTechnicalCups() {
  const response = await axios
    .post(`${urlApiLaravel}api/v2/doctor-search-engine/technical-cups`)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      console.log("No se encontraron technical-cups");
    });

  return response;
}

export async function getCupsHomeCare(id) {
  const response = await axios
    .post(
      `${urlApiLaravel}api/v2/doctor-search-engine/cups`,
      { city_id: id }
    )
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      Swal.fire("No se encontraron cups");
    });

  return response;
}

export async function getDoctorExamsProcedures(body) {
  const response = await axios
    .post(`${urlApiLaravel}api/v2/doctor-search-engine`, body)
    .then((res) => {
      if (res.status === 200) {
        return res.data.data;
      }
    })
    .catch(() => {
      console.log("No se encontraron ExamsProcedures");
    });

  return response;
}

export function getCitiesReducer(stateId) {
  return async (dispatch) => {
    return await axios
      .get(`${urlApiLaravel}api/v1/cities/${stateId}/state`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_CITIES,
            payload: res.data,
          });
        }
        return res.data.data;
      })
      .catch(() => {
        console.log("No se encontraron ciudades");
      });
  };
}

export async function doctorSearchEngine(data) {
  const response = await axios
    .post(`${urlApiLaravel}api/v2/doctor-search-engine`, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "No se encontraron resultados a esta busqueda",
      });
    });

  return response;
}

export function sendNotificationVideoCall(data) {
  return async (dispatch) => {
    return await axios
      .post(
        `${urlApiLaravel}api/v1/notifications/video-consultation/started`,
        data
      )
      .then((res) => {
        if (res.data.code === 0) {
          return res.data.data;
        }
      })
      .catch(() => {
        Swal.fire({
          title: "No se pudo enviar la notificacion de la video llamada",
        });
      });
  };
}

export function CreateNewProfileByPages(resource, profileByPage) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, profileByPage).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PROFILES_BY_PAGE,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function DeleteProfileByPages(resource, idProfileByPage) {
  return (dispatch) => {
    return axios
      .delete(`${urlApi}${resource}/${idProfileByPage}`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_PROFILES_BY_PAGE,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function getUserMenu(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_USER_MENU,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getValues(resource, option) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}/${option}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_VALUES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function createNewNode(resource, newNode) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, newNode).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_USER_MENU,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function updateAccessProfile(resource, profile) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, profile).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PROFILES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getDocumentType(resource, docType) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, docType).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_DOCUMET_TYPES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getDocumentType2(resource) {
  return axios.post(`${urlApi}${resource}`).then((res) => {
    return res.data;
  });
}

export function getBloodType(resource, bloodType) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, bloodType).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_BLOOD_TYPE,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getCities(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        // dispatch({
        //   type: GET_CITIES,
        //   payload: res.data,
        // });
      }
      return res.data;
    });
  };
}

// export function getDepartments(resource, department) {
//   return (dispatch) => {
//     return axios.post(`${urlApi}${resource}`, department).then((res) => {
//       if (res.data.code === 0) {
//         dispatch({
//           type: GET_DEPARTMENTS,
//           payload: res.data,
//         });
//       }
//       return res.data;
//     });
//   };
// }

// export function getCountries(resource, country) {
//   return (dispatch) => {
//     return axios.post(`${urlApi}${resource}`, country).then((res) => {
//       if (res.data.code === 0) {
//         dispatch({
//           type: GET_COUNTRY,
//           payload: res.data,
//         });
//       }
//       return res.data;
//     });
//   };
// }

export function getCountries() {
  return (dispatch) => {
    return axios.get(`${urlApiLaravel}api/v1/countries`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_COUNTRY,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getContactsType(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CONTACTS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getPaymentsForms(resource, paymentForm) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, paymentForm).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PAYMENT_FORMS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function updateStateInvoiceProfile(resource, stateInvoiceProfile) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, stateInvoiceProfile)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_PROFILES,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function getCurrencies(resource, currency) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, currency).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CURRENCIES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getRegimeTypes(resource, regimeType) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, regimeType).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_REGIME_TYPE,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getPersonTypes(resource, personType) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, personType).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PERSON_TYPE,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getOrientationReportInvoice(
  resource,
  orinetationReportInvoice
) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, orinetationReportInvoice)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_ORIENTATION_REPORT,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function getConfigCalendar(resource, configCalendar) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, configCalendar).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CONFIG_CALENDAR,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

/**
 * Api Laravel
 */
export function getApiPatient(patient) {
  return (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: true });
    return axios
      .get(`${urlApiLaravel}api/v1/patients/${patient.id}`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_PATIENTS,
            payload: res.data,
          });
          dispatch({
            type: GET_PATIENT_LARAVEL,
            payload: res.data,
          });
        }
        dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: false });
        return res.data;
      });
  };
}

/**
 * Marital Status
 */
export function getApiMaritalStatus() {
  return (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: true });
    return axios.get(`${urlApiLaravel}api/v1/marital-status`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_MARITAL_STATUS,
          payload: res.data,
        });
      }
      dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: false });
      return res.data;
    });
  };
}

/**
 * Blood Types
 */
export function getApiBloodTypes() {
  return (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: true });
    return axios.get(`${urlApiLaravel}api/v1/blood-types`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_BLOOD_TYPES,
          payload: res.data,
        });
      }
      dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: false });
      return res.data;
    });
  };
}

/**
 * Agreements
 */
export function getApiAgreements() {
  return (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: true });
    return axios.get(`${urlApiLaravel}api/v1/agreements`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_AGREEMENTS,
          payload: res.data,
        });
      }
      dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: false });
      return res.data;
    });
  };
}

/**
 * Plans
 */
export function getApiPlans() {
  return (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: true });
    return axios.get(`${urlApiLaravel}api/v1/plans`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PLAN,
          payload: res.data,
        });
      }
      dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: false });
      return res.data;
    });
  };
}

/**
 * Health Entities
 */
export function getApiHealthEntities() {
  return (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: true });
    return axios.get(`${urlApiLaravel}api/v1/health-entities`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_HEALTH_ENTITIES,
          payload: res.data,
        });
      }
      dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: false });
      return res.data;
    });
  };
}

export function setApiPatientUpdate(resource, patient) {
  return (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: true });
    return axios.post(`${urlApi}${resource}`, patient).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PATIENTS,
          payload: res.data,
        });
      }
      dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: false });
      return res.data;
    });
  };
}

export function getPatients(resource, patient) {
  return (dispatch) => {
    dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: true });
    return axios.post(`${urlApi}${resource}`, patient).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PATIENTS,
          payload: res.data,
        });
      }
      dispatch({ type: SET_EXECUTING_REQUEST_GENERIC, value: false });
      return res.data;
    });
  };
}

export async function getPatientsNew(resource, patient) {
  const response = await axios
    .post(`${urlApi}${resource}`, patient)
    .then((res) => {
      if (res.data.code === 0) {
        return res.data.data;
      }
    })
    .catch(() => {
      Swal.fire({
        title: "No se encontraron getPatientsNew",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    });

  return response;
}

export function getSocialNetworks(resource, socialNetwork) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, socialNetwork).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_SOCIAL_NETWORKS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getEthnicGroups(resource, ethnicGroup) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, ethnicGroup).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_ETHNIC_GROUPS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getSexualOrientation(resource, sexualOrientation) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, sexualOrientation).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_SEXUAL_ORIENTATION,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getGender(resource, gender) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, gender).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_GENDER,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getBank(resource, bank) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, bank).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_BANK,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getFindDoctor(resource, string) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}?cadenaBusqueda=${string}`, null)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_FINDDOCTOR,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function getPlan(resource, plan) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, plan).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PLAN,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getRango(resource, rango) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, rango).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_RANGO,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getAfiliacion(resource, afiliacion) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, afiliacion).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_AFILIACION,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getEscolaridad(resource, escolaridad) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, escolaridad).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_ESCOLARIDAD,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getOcupacion(resource, ocupacion) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, ocupacion).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_OCUPACION,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getOrigenContacto(resource, origenCont) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, origenCont).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_ORIGENCONTACTO,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getParentesco(resource, parentesco) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, parentesco).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PARENTESCO,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getFuncionalidad(resource, funcionalidad) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, funcionalidad).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_FUNCIONALIDAD,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getFuncionalidadXUsuario(resource, funcionalidadxUsuario) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, funcionalidadxUsuario)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_FUNCIONALIDAD_X_USUARIO,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function GetUnidadesMedida(resource, unidadesMedida) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, unidadesMedida).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_UNIDADES_MEDIDA,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function GetReporte1(resource, reporte1) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, reporte1).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_REPORTE_1,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function SetDatosSignalRConexion(conect) {
  return {
    type: SIGNALRCON,
    payload: conect,
  };
}

export function SetDatosSignalRProxy(proxy) {
  return {
    type: SIGNALRPROXY,
    payload: proxy,
  };
}

export function setLastAction(value) {
  return {
    type: SET_LAST_ACTION,
    value,
  };
}

export function getBancos(key) {
  return () => {
    // var headers = {
    //   "Access-Control-Allow-Origin": "*",
    //   "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    //   "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    // };

    // header('Access-Control-Allow-Origin: *');
    // header('Access-Control-Allow-Methods: GET, POST, PATCH, PUT, DELETE, OPTIONS');
    // header('Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token');

    //axios.get("https://dog.ceo/api/breeds/list/all",
    //{ headers: {'Content-Type': 'application/json'}}

    return axios
      .get(
        `https://secure.payco.co/restpagos/pse/bancos.json?public_key=${key}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET, POST",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        }
      )
      .then((res) => {
        //  if (res.data.code === 0) {
        //    dispatch({
        //      type: GET_PARENTESCO,
        //      payload: res.data
        //    });
        //  }
        return res.data;
      });
  };
}

export function createBodySystem(resource, bodySysyem) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, bodySysyem).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion updateBodySystem
          value: CREATE_BODY_SYSTEM, // igual a la accion updateBodySystem
        });
      }
      return res.data;
    });
  };
}

export function deleteBodySystem(resource, bodySysyem) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, bodySysyem).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion updateBodySystem
          value: DELETE_BODY_SYSTEM, // igual a la accion updateBodySystem
        });
      }
      return res.data;
    });
  };
}

export function getBodySystems(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_BODY_SYSTEMS,
          payload: res.data, // enviaremos los datos que llegan en la respuesta en el key bodySystems del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function updateBodySystem(resource, bodySysyem) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, bodySysyem).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // con este tipo actualizamos el reducer Generic con un ultimo estado que nos permitira mostrar un mensaje cuando finalice la solicitud
          value: CREATE_BODY_SYSTEM, // el valor para el lastAction del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function createVariableUnitsxExams(resource, variableUnitsxExams) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, variableUnitsxExams)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: SET_LAST_ACTION, // igual a la accion updateBodySystem
            value: CREATE_VARIABLE_UNITSXEXAMS, // igual a la accion updateBodySystem
          });
        }
        return res.data;
      });
  };
}

export function deleteVariableUnitsxExams(resource, variableUnitsxExams) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, variableUnitsxExams)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: SET_LAST_ACTION, // igual a la accion updateBodySystem
            value: DELETE_VARIABLE_UNITSXEXAMS, // igual a la accion updateBodySystem
          });
        }
        return res.data;
      });
  };
}

export function getVariableUnitsxExams(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_VARIABLE_UNITSXEXAMS,
          payload: res.data, // enviaremos los datos que llegan en la respuesta en el key bodySystems del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function updateVariableUnitsxExams(resource, variableUnitsxExams) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, variableUnitsxExams)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: SET_LAST_ACTION, // con este tipo actualizamos el reducer Generic con un ultimo estado que nos permitira mostrar un mensaje cuando finalice la solicitud
            value: CREATE_VARIABLE_UNITSXEXAMS, // el valor para el lastAction del reducer Generic
          });
        }
        return res.data;
      });
  };
}

export function createTypesDataxExams(resource, typesDataxExams) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, typesDataxExams).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion updateBodySystem
          value: CREATE_TYPES_DATAXEXAMS, // igual a la accion updateBodySystem
        });
      }
      return res.data;
    });
  };
}

export function deleteTypesDataxExams(resource, typesDataxExams) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, typesDataxExams).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion updateBodySystem
          value: DELETE_TYPES_DATAXEXAMS, // igual a la accion updateBodySystem
        });
      }
      return res.data;
    });
  };
}

export function getTypesDataxExams(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_TYPES_DATAXEXAMS,
          payload: res.data, // enviaremos los datos que llegan en la respuesta en el key bodySystems del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function updateTypesDataxExams(resource, typesDataxExams) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, typesDataxExams).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // con este tipo actualizamos el reducer Generic con un ultimo estado que nos permitira mostrar un mensaje cuando finalice la solicitud
          value: CREATE_TYPES_DATAXEXAMS, // el valor para el lastAction del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function createListsSearchxExams(resource, listsSearchxExams) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, listsSearchxExams).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion updateBodySystem
          value: CREATE_LISTS_SEARCHXEXAMS, // igual a la accion updateBodySystem
        });
      }
      return res.data;
    });
  };
}

export function deleteListsSearchxExams(resource, listsSearchxExams) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, listsSearchxExams).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion updateBodySystem
          value: DELETE_LISTS_SEARCHXEXAMS, // igual a la accion updateBodySystem
        });
      }
      return res.data;
    });
  };
}

export function getListsSearchxExams(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_LISTS_SEARCHXEXAMS,
          payload: res.data, // enviaremos los datos que llegan en la respuesta en el key bodySystems del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function updateListsSearchxExams(resource, listsSearchxExams) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, listsSearchxExams).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // con este tipo actualizamos el reducer Generic con un ultimo estado que nos permitira mostrar un mensaje cuando finalice la solicitud
          value: CREATE_LISTS_SEARCHXEXAMS, // el valor para el lastAction del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function createDiagnosticosCie(resource, diagnosticosCie) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, diagnosticosCie).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion updateBodySystem
          value: CREATE_DIAGNOSTICOS_CIE, // igual a la accion updateBodySystem
        });
      }
      return res.data;
    });
  };
}

export function deleteDiagnosticosCie(resource, diagnosticosCie) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, diagnosticosCie).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion updateBodySystem
          value: DELETE_DIAGNOSTICOS_CIE, // igual a la accion updateBodySystem
        });
      }
      return res.data;
    });
  };
}

export function getDiagnosticosCie(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_DIAGNOSTICOS_CIE,
          payload: res.data, // enviaremos los datos que llegan en la respuesta en el key bodySystems del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function updateDiagnosticosCie(resource, diagnosticosCie) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, diagnosticosCie).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // con este tipo actualizamos el reducer Generic con un ultimo estado que nos permitira mostrar un mensaje cuando finalice la solicitud
          value: CREATE_DIAGNOSTICOS_CIE, // el valor para el lastAction del reducer Generic
        });
      }
      return res.data;
    });
  };
}
export function createSymptom(resource, symptom) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, symptom).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: CREATE_SYMPTOM,
        });
      }
      return res.data;
    });
  };
}

export function deleteSymptom(resource, symptom) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, symptom).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: DELETE_SYMPTOM,
        });
      }
      return res.data;
    });
  };
}

export function getSymptoms(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_SYMPTOMS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function updateSymptom(resource, symptom) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, symptom).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: CREATE_SYMPTOM,
        });
      }
      return res.data;
    });
  };
}

export function createValuesxSearchList(resource, valuesxSearchList) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, valuesxSearchList).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: CREATE_VALUESXSEARCHLIST,
        });
      }
      return res.data;
    });
  };
}

export function deleteValuesxSearchList(resource, valuesxSearchList) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, valuesxSearchList).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: DELETE_VALUESXSEARCHLIST,
        });
      }
      return res.data;
    });
  };
}

export function getValuesxSearchList(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_VALUESXSEARCHLIST,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function updateValuesxSearchList(resource, valuesxSearchList) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, valuesxSearchList).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: CREATE_VALUESXSEARCHLIST,
        });
      }
      return res.data;
    });
  };
}

export function createCupsTecnica(resource, cupsTecnica) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, cupsTecnica).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: CREATE_CUPSTECNICA,
        });
      }
      return res.data;
    });
  };
}

export function deleteCupsTecnica(resource, cupsTecnica) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, cupsTecnica).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: DELETE_CUPSTECNICA,
        });
      }
      return res.data;
    });
  };
}

export function getCupsTecnica(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_CUPSTECNICA,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function updateCupsTecnica(resource, cupsTecnica) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, cupsTecnica).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: CREATE_CUPSTECNICA,
        });
      }
      return res.data;
    });
  };
}

export function getGroupCups(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_GROUP_CUPS,
          payload: res.data, // enviaremos los datos que llegan en la respuesta en el key bodySystems del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function createPhysicalRegion(resource, physicalRegion) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, physicalRegion).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion
          value: CREATE_PHYSICAL_REGION, // igual a la accion
        });
      }
      return res.data;
    });
  };
}

export function deletePhysicalRegion(resource, physicalRegion) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, physicalRegion).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion update
          value: DELETE_PHYSICAL_REGION, // igual a la accion u
        });
      }
      return res.data;
    });
  };
}

export function getPhysicalRegion(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_PHYSICAL_REGION,
          payload: res.data, // enviaremos los datos que llegan en la respuesta en el key bodySystems del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function updatePhysicalRegion(resource, physicalRegion) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, physicalRegion).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // con este tipo actualizamos el reducer Generic con un ultimo estado que nos permitira mostrar un mensaje cuando finalice la solicitud
          value: CREATE_PHYSICAL_REGION, // el valor para el lastAction del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function createBackground(resource, background) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, background).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: CREATE_BACKGROUND,
        });
      }
      return res.data;
    });
  };
}

export function deleteBackground(resource, background) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, background).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: DELETE_BACKGROUND,
        });
      }
      return res.data;
    });
  };
}

export function getBackground(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_BACKGROUND,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function updateBackground(resource, background) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, background).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION,
          value: CREATE_BACKGROUND,
        });
      }
      return res.data;
    });
  };
}

export function createAntecedentGroup(resource, antecedentGroup) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, antecedentGroup).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion updateBodySystem
          value: CREATE_ANTECEDENT_GROUP, // igual a la accion updateBodySystem
        });
      }
      return res.data;
    });
  };
}

export function deleteAntecedentGroup(resource, antecedentGroup) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, antecedentGroup).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // igual a la accion updateBodySystem
          value: DELETE_ANTECEDENT_GROUP, // igual a la accion updateBodySystem
        });
      }
      return res.data;
    });
  };
}

export function getAntecedentGroup(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_ANTECEDENT_GROUP,
          payload: res.data, // enviaremos los datos que llegan en la respuesta en el key bodySystems del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function updateAntecedentGroup(resource, antecedentGroup) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, antecedentGroup).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: SET_LAST_ACTION, // con este tipo actualizamos el reducer Generic con un ultimo estado que nos permitira mostrar un mensaje cuando finalice la solicitud
          value: CREATE_ANTECEDENT_GROUP, // el valor para el lastAction del reducer Generic
        });
      }
      return res.data;
    });
  };
}

export function createAnthropometricaMeasures(
  resource,
  anthropometricaMeasures
) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, anthropometricaMeasures)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: SET_LAST_ACTION,
            value: CREATE_ANTHROPOMETRICA_MEASURES,
          });
        }
        return res.data;
      });
  };
}

export function deleteAnthropometricaMeasures(
  resource,
  anthropometricaMeasures
) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, anthropometricaMeasures)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: SET_LAST_ACTION,
            value: DELETE_ANTHROPOMETRICA_MEASURES,
          });
        }
        return res.data;
      });
  };
}

export function getAnthropometricaMeasures(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_ANTHROPOMETRICA_MEASURES,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function updateAnthropometricaMeasures(
  resource,
  anthropometricaMeasures
) {
  return (dispatch) => {
    return axios
      .post(`${urlApi}${resource}`, anthropometricaMeasures)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: SET_LAST_ACTION,
            value: CREATE_ANTHROPOMETRICA_MEASURES,
          });
        }
        return res.data;
      });
  };
}

export function sendAppointmentReminder(item) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${urlApiMessagebird}appointment-reminder`, item)
      .then((obj) => {
        resolve(obj.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function insertPaymentQuoteOffered(resource, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${urlApi}${resource}`, params)
      .then((obj) => {
        resolve(obj.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function newInsertPaymentQuoteOffered(offerAppointmentId) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${urlApiLaravel}api/v1/purchase-order/${offerAppointmentId}/confirm-and-pay`
      )
      .then((obj) => {
        resolve(obj.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
